<template>
  <div>
   <!-- <div id="centreLeft1Chart" style="width:4.25rem; height: 3.25rem;"></div>-->
    <apexchart :options="this.chartOptions1" :series="this.series1"
               height="360" type="donut"/>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";

export default {
  data() {
    return {
      chart: null,
      series1: [41, 55, 22,12],
      chartOptions1: {
        chart: {
          type: 'donut',
        },
        legend: {
          show:true,
          labels: {
            colors: ["#fff"]
          }
        },
        labels: ["挠度计", "应变计", "裂缝计",'锚索计'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom',
              show:false,
             textStyle: {
               color: "#fff"
             }
            }
          }
        }],
        stroke: { width: 0 },
        colors: ['#7961F9', '#FF9F43', '#EA5455', '#70eadb']
      },
    };
  },
  mixins: [echartMixins],
  mounted() {
   // this.draw();
  },
  methods: {
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("centreLeft1Chart"));
      //  ----------------------------------------------------------------

      this.chart.setOption({
        color: [
          "#37a2da",
          "#32c5e9",
          "#9fe6b8",
          "#ffdb5c",
          "#ff9f7f",
          "#fb7293",
          "#e7bcf3",
          "#8378ea"
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        toolbox: {
          show: true
        },
        calculable: true,
        legend: {
          orient: "horizontal",
          icon: "circle",
          bottom: 0,
          x: "center",
          data: ["电磁阀", "摄像头", "灌溉器", "网关"],
          textStyle: {
            color: "#fff"
          }
        },
        series: [
          {
            name: "设备统计",
            type: "pie",
            radius: [10, 60],
            roseType: "area",
            center: ["50%", "40%"],
            data: [
              { value: 10, name: "电磁阀" },
              { value: 5, name: "摄像头" },
              { value: 15, name: "灌溉器" },
              { value: 25, name: "网关" },
            ]
          }
        ]
      });
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
