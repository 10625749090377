<template>
  <div>
    <el-cascader
            style="width: 300px;"
            :options="optionsList"
            :props="props"
            v-model="sbList"
            @change="fchg"
            clearable></el-cascader>
    <div id="bottomLeftChart" style="width:100%;height:4.2rem;"></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";
export default {
  data() {
    return {
      briageNo:'R-22',
      equipId:'720046000C50475842303620-1',
      chart: null,
      options: [ ],
      optionsList:[],
      sbList:['R-22','720046000C50475842303620-1'],
      props: { multiple: false },
      birdgeList:[],
      equipOptionAll:[],
      equipOption: [],
      equipData: [],
   /*   sbList:[],
      props: { multiple: true },
      options: [ ],
      optionsList:[],*/
      echartsOption:{

        legend: {
          data: [],
          bottom:0,
          left:'center',
          textStyle:{
            color:'#ffffff',
          }
        },
        xAxis: {
          name: '时间',
          nameTextStyle: {
            fontWeight: 20,
            fontSize: 1,
            color: "rgba(255,255,255,.8)",
          },
          textStyle:{
            color:'#ffffff',
          },
          type: 'category',
          boundaryGap: false,
          data: this.date,	// 绑定实时数据数组
          axisLabel: {
            textStyle: {
              color: '#fff'
            }
          }
        },
        yAxis: {
          textStyle:{
            color:'#ffffff',
          },
          name: '数值',
          nameTextStyle: {
            fontWeight: 20,
            fontSize: 1,
            color: "rgba(255,255,255,.8)",
          },
          type: 'value',
          scale: true,
          boundaryGap: ['15%', '15%'],
          axisLabel: {
            interval: 'auto',
            formatter: '{value}',
            textStyle: {
              color: '#fff'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: ''
        },
        series: [
          {
            name: this.showdanwei,
            type: 'line',
            smooth: true,
            data: this.yieldRate,	// 绑定实时数据数组
          },
          {
            name: this.showdanwei1,
            type: 'line',
            smooth: true,
            data: this.yieldRate1,	// 绑定实时数据数组
          }
        ]
      },

      showdanwei:'',
      showdanwei1:'',
      yieldRate: [],//y轴
      yieldRate1:[],
      yieldRate2:[],
      date: [],//x轴
      date2: [],//x轴
      myChart:null,
      myChart1:null,
      websocket:'',
      queryParams: {
        type: "nd",
        br:['R-22']
      },
      currentType: "nd",
    };
  },
  mixins: [echartMixins],
  mounted() {
    this.higchart();
    this.loadBridge();
  },
  methods: {
    fchg(){ console.log(this.sbList)
      if(this.sbList.length>0){
        this.briageNo=this.sbList[0];
        this.equipId=this.sbList[1];
      }

    },
    loadBridge(){
      this.$http.get('/data/bridgeTbl/list').then(res=>{
        console.log(res)
        this.birdgeList=res.data.rows;
        //this.options=[];
        this.birdgeList.forEach(item=>{
          this.options.push({
            value:item.name,
            label:item.name
          });
        })
        this.getEquipData();
      })

    },
    async getEquipData() {
      this.$http.get('/data/sensorTbl/listAll').then(res=>{
        this.equipOption=res.data;
        this.equipOptionAll = res.data;
        this.options.forEach(item=>{
          item.children=[];
          this.equipOptionAll.forEach(wd=>{
            if(wd.name.indexOf(item.label)!=-1){
              item.children.push({
                value:wd.equipId,
                label:wd.name
              });
            }

          })
        })
        this.optionsList=this.options;
      })

  /*    const response = await equipData();
      this.equipOption=response;
      this.equipOptionAll = response;
      this.options.forEach(item=>{
        item.children=[];
        this.equipOptionAll.forEach(wd=>{
          if(wd.name.indexOf(item.label)!=-1){
            item.children.push({
              value:wd.equipId,
              label:wd.name
            });
          }

        })
      })
      this.optionsList=this.options;
*/
    },
    higchart(){
      setTimeout(() => {
        /* legend={
             'data':[this.showdanwei],
             'left': 100
         };
         serios1 = [{
             'name': this.showdanwei,
             'type': 'line',
             'smooth': true,
             'data': this.yieldRate,	// 绑定实时数据数组
         }, {
             'name': this.showdanwei1,
             'type': 'line',
             'smooth': true,
             'data': this.yieldRate1,	// 绑定实时数据数组
         }]*/

        this.echartsOption.tooltip.formatter = "挠度值:{c0}mm";
        //this.echartsOption.legend = legend;
        // this.echartsOption.series = serios1;
        this.myChart = this.$echarts.init(document.getElementById('bottomLeftChart'), 'light');
        this.myChart.setOption(this.echartsOption, true, false);	// echarts设置初始化选项
        this.initWebSocket();
        this.myVar = setInterval(this.addData, 1000);	// 每1秒更新实时数据到折线图
      },1000)



    },
    addData(){

      //console.log(this.tableVaule1);
      for (var i in this.tableVaule) {
        if (this.tableVaule[i].briageNo == this.briageNo) {
          var shuzuvalue = this.tableVaule[i].equipType;
          for (var j in shuzuvalue) {
            if (shuzuvalue[j].equipBeans[0].equipId == this.equipId) {
              if("zxz" === this.currentType || "ybz" === this.currentType){
                for(var k = 0;k<this.tableVaule1.length;k++){
                  if(this.tableVaule1[k].equipBeans[0].equipId == this.row1){
                    if(this.tableVaule1[k].equipBeans[0].value === "" || this.tableVaule1[k].equipBeans[0].value === null){
                      this.dispalyValue1 = 0
                    }else{
                      this.dispalyValue1 = parseFloat().toFixed(2)
                    }
                  }
                }
              }
              this.dispalyValue = shuzuvalue[j].equipBeans[0].value;
              //var chooseTime = this.$moment(shuzuvalue[j].equipBeans[0].createTime).format('YYYY-MM-DD HH:mm:ss');
              var dates = new Date();
              var chooseTime = this.$moment(dates).format('YYYY-MM-DD HH:mm:ss');
              this.displayTime = chooseTime.substring(11, 19);
              if (this.dispalyValue === '' || this.dispalyValue === null) {
                this.dispalyValue = 0;
              } else {
                var zhongjianvalue = this.dispalyValue;
                this.dispalyValue = parseFloat(zhongjianvalue).toFixed(2);
              }
            }
          }
        }
      }
      //debugger
      this.dialogDeviceValue = this.dispalyValue;
      // if(this.dispalyValue !== 0){
      this.yieldRate.push(this.dispalyValue);
      if(this.displayTime)
        this.date.push(this.displayTime);

      //console.log("温度值："+this.dispalyValue1+"第一个的值："+this.dispalyValue);
      // }

      this.echartsOption.series[1].data = [];
      if("zxz" === this.currentType || "ybz" === this.currentType){
        if(this.dispalyValue1 !== 0){
          this.yieldRate1.push(this.dispalyValue1);
        }
        if (this.yieldRate1.length >= 30) {
          this.yieldRate1.shift();
        }
        this.echartsOption.series[1].data = this.yieldRate1;
      }
      if (this.yieldRate.length >= 30) {
        this.yieldRate.shift();
      }

      if (this.date.length >= 30) {
        this.date.shift();
      }
      this.echartsOption.xAxis.data = this.date;
      this.echartsOption.series[0].data = this.yieldRate;
      this.myChart.setOption(this.echartsOption, true, false);
    },
    initWebSocket() {
      //初始化
      this.websocket = new WebSocket('ws://60.208.84.149:8081/noauth/websocket');
      //  this.websocket = new WebSocket('ws://127.0.0.1:8081/noauth/websocket');
      this.websocket.onopen = this.websocketonopen;
      this.websocket.onerror = this.websocketonerror;
      this.websocket.onmessage = this.websocketonmessage;
    },
    websocketonopen() {
      //发送
      console.log("WebSocket连接成功");
      // this.websocket.send(this.queryParams.br);
    },
    websocketonerror() {
      //错误
      console.log("WebSocket连接发生错误");
    },
    websocketonmessage(e) {

      this.tableVaule1 = [];
      //数据接收
      var std = e.data;
      //处理逻辑
      if (std.indexOf('连接成功') === -1) {
        const value123 = JSON.parse(e.data);
        const values = value123.socket;
        var mdiileValue1 = [];
        for (var i = 0; i < values.length; i++) {
          var middleValue = [];
         // let l=false;
          //console.log(this.queryParams.br,'111111111111')
          this.queryParams.br.forEach(item=>{
            if(values[i].briageName==item){
              // continue;
             // l=true;
            }
          })
          //if(!l)continue;
          /*    if(values[i].briageName!=this.queryParams.br.join(",")){
                  continue;
              }*/
          /*  if(values[i].maxminList){
                for (var j = 0; j < values[i].maxminList.length; j++) {
                    if (values[i].maxminList[j].type === this.currentType) {
                        this.maxv = values[i].maxminList[j].maxv;
                        this.minv = values[i].maxminList[j].minv;
                    }
                }
            }*/
          console.log("this.currentType",this.currentType)
          for (var j = 0; j < values[i].equipType.length; j++) {
            // console.log(values[i].equipType[j].type,this.currentType)
            if (values[i].equipType[j].type === this.currentType) {
              var list = values[i].equipType[j];
              middleValue.push(list);
            }
            if ("ybz" === this.currentType) {
              if (values[i].equipType[j].type === "ybwd") {
                let list = values[i].equipType[j];
                this.tableVaule1.push(list);
              }
            }
            if ("zxz" === this.currentType) {
              if (values[i].equipType[j].type === "smwd") {
                let list = values[i].equipType[j];
                this.tableVaule1.push(list);
              }
            }
          }
          //console.log('middleValue',middleValue)
          values[i].equipType = middleValue;
          if (values[i].equipType.length > 0) {
            mdiileValue1.push(values[i]);
          }
        }
       // console.log(mdiileValue1)
        this.tableVaule = mdiileValue1;
        if (this.tableVaule.length === 0) {
          console.log('0000000000')
          //this.websocket.close();
          // this.websocket.onclose = this.websocketclose;

        }
        //this.loading.close();
        // this.tableVaule
      }
    },
    websocketclose() {
      //关闭
      console.log("connection closed");
    },
    draw() {
      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("bottomLeftChart"));
      //  ----------------------------------------------------------------
      let category = [
        "电磁阀001",
        "电磁阀002",
        "电磁阀003",
        "电磁阀004",
        "电磁阀005",
        "电磁阀006",
        "电磁阀007",
        "电磁阀008",
        "电磁阀009",
        "电磁阀010",
        "电磁阀011",
        "电磁阀012",
        "电磁阀013",
        "电磁阀014"
      ];
      let lineData = [
        680,
        407,
        540,
        283,
        328,
        360,
        398,
        447,
        484,
        504,
        560,
        626,
        595,
        595
      ];
      let barData = [
        46,
        50,
        55,
        65,
        75,
        85,
        99,
        120,
        140,
        210,
        230,
        240,
        250,
        33
      ];
      let rateData = [];
      for (let i = 0; i < 14; i++) {
        let rate = 1 - ( barData[i] / lineData[i]);
        rateData[i] = rate.toFixed(5);
      }

      let option = {
        title: {
          text: "",
          x: "center",
          y: 0,
          textStyle: {
            color: "#B4B4B4",
            fontSize: 16,
            fontWeight: "normal"
          }
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,0.1)",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              backgroundColor: "#7B7DDC"
            }
          }
        },
        legend: {
          data: ["下发成功", "下发失败", "成功率"],
          textStyle: {
            color: "#B4B4B4"
          },
          top: "0%"
        },
        grid: {
          x: "8%",
          width: "88%",
          y: "10%"
        },
        xAxis: {
          data: category,
          axisLine: {
            lineStyle: {
              color: "#B4B4B4"
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },

            axisLabel: {
              formatter: "{value} "
            }
          },
          {
            splitLine: { show: false },
            axisLine: {
              lineStyle: {
                color: "#B4B4B4"
              }
            },
            axisLabel: {
              formatter: "{value} "
            }
          }
        ],
        series: [
          {
            name: "成功率",
            type: "line",
            smooth: true,
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: "#F02FC2"
              }
            },
            data: rateData
          },

          {
            name: "下发成功",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#956FD4" },
                  { offset: 1, color: "#3EACE5" }
                ])
              }
            },
            data: barData
          },

          {
            name: "下发失败",
            type: "bar",
            barGap: "-100%",
            barWidth: 10,
            itemStyle: {
              normal: {
                barBorderRadius: 5,
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(156,107,211,0.8)" },
                  { offset: 0.2, color: "rgba(156,107,211,0.5)" },
                  { offset: 1, color: "rgba(156,107,211,0.2)" }
                ])
              }
            },
            z: -12,
            data: lineData
          }
        ]
      };
      this.chart.setOption(option);
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
