<template>
  <div>
    <el-cascader
            style="width: 300px;"
            :options="optionsList"
            :props="props"
            v-model="sbList"
            @change="fchg"
            clearable></el-cascader>
    <div id="bottomRightChart" style="width:100%;height:4.2rem;"></div>
  </div>
</template>

<script>
import echartMixins from "@/utils/resizeMixins";

export default {
  data() {
    return {
      chart: null,
      options: [ ],
      optionsList:[],
      sbList:['R-22','720046000C50475842303620-1'],
      props: { multiple: false },
      date2: [],//x轴
      yieldRate2:[],
      briageNo:'R-22',
      equipId:'720046000C50475842303620-1',
      myChart1:null,
      echartsOption2: {
        legend: {
          data: [],
        },
        xAxis: {
          name: '时间',
          nameTextStyle: {
            fontWeight: 20,
            fontSize: 1
          },
          type: 'category',
          boundaryGap: false,
          data: this.date,	// 绑定实时数据数组
          axisLabel: {
            textStyle: {
              color: '#fff'
            }
          }
        },
        yAxis: {
          name: '数值',
          nameTextStyle: {
            fontWeight: 20,
            fontSize: 1
          },
          type: 'value',
          scale: true,
          boundaryGap: ['15%', '15%'],
          axisLabel: {
            interval: 'auto',
            formatter: '{value}',
            textStyle: {
              color: '#fff'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
        },
        series: [
          {
            name:'数值',
            type:'line',
            smooth: true,
            data: this.yieldRate,	// 绑定实时数据数组
          }
        ]
      }
    };
  },
  mounted() {
    this.draw1();
    this.charTimg();
    this.loadBridge();
  },
  mixins: [echartMixins],
  methods: {
    loadBridge(){
      this.$http.get('/data/bridgeTbl/list').then(res=>{
        console.log(res)
        this.birdgeList=res.data.rows;
        //this.options=[];
        this.birdgeList.forEach(item=>{
          this.options.push({
            value:item.name,
            label:item.name
          });
        })
        this.getEquipData();
      })

    },
    async getEquipData() {
      this.$http.get('/data/sensorTbl/listAll').then(res=>{
        this.equipOption=res.data;
        this.equipOptionAll = res.data;
        this.options.forEach(item=>{
          item.children=[];
          this.equipOptionAll.forEach(wd=>{
            if(wd.name.indexOf(item.label)!=-1){
              item.children.push({
                value:wd.equipId,
                label:wd.name
              });
            }

          })
        })
        this.optionsList=this.options;
      })

      /*    const response = await equipData();
          this.equipOption=response;
          this.equipOptionAll = response;
          this.options.forEach(item=>{
            item.children=[];
            this.equipOptionAll.forEach(wd=>{
              if(wd.name.indexOf(item.label)!=-1){
                item.children.push({
                  value:wd.equipId,
                  label:wd.name
                });
              }

            })
          })
          this.optionsList=this.options;
    */
    },
    fchg(){
      if(this.sbList.length>0){
        this.briageNo=this.sbList[0];
        this.equipId=this.sbList[1];
        this.draw1();
      }
    },
    charTimg() {
      setInterval(() => {
        //this.draw1();
      }, 6000);
    },
    draw1(){
      this.$http.get('/data/tbl/getVauleInfo/f18468b444ca11eb957500163e0ea4b3/'+ this.equipId, {
        params: {}
      }).then(res => {
        console.log(res)
        //  this.gridDataList = res.data.rows;
        //this.total = Number(res.data.records);
        var value = res.data.data.list;
        console.log('value',value)
        this.yieldRate2=[];
        this.date2=[];
        for (let j in value){
          this.yieldRate2.push(value[j].resultValue);
          this.date2.push(value[j].createTime.toString());
        }
        this.echartsOption2.xAxis.data = this.date2;
        this.echartsOption2.series[0].data = this.yieldRate2;
        console.log('this.date2',this.date2)
        setTimeout(() => {
          this.myChart1 = this.$echarts.init(document.getElementById("bottomRightChart"));
          this.myChart1.setOption(this.echartsOption2,true,false);
        },100);
      });
    },
    draw() {



      // 基于准备好的dom，初始化echarts实例
      this.chart = this.$echarts.init(document.getElementById("bottomRightChart"));
      //  ----------------------------------------------------------------
      // 数据
      let dateBase = new Date();
     // let year = dateBase.getFullYear();
      let dottedBase = +dateBase + 1000 * 3600 * 24;
      let weekCategory = [];

    /*  let radarData = [];
      let radarDataAvg = [];*/
      let maxData = 12000;
      let weekMaxData = [];
      let weekLineData = [];

      // 周数据
      for (let i = 0; i < 7; i++) {
        // 日期
        var date = new Date((dottedBase -= 1000 * 3600 * 24));
        weekCategory.unshift([date.getMonth() + 1, date.getDate()].join("/"));

        // 折线图数据
        weekMaxData.push(maxData);
        var distance = Math.round(Math.random() * 11000 + 500);
        weekLineData.push(distance);

        // 雷达图数据

       /* var averageSpeed = +(Math.random() * 5 + 3).toFixed(3);
        var maxSpeed = averageSpeed + +(Math.random() * 3).toFixed(2);
        var hour = +(distance / 1000 / averageSpeed).toFixed(1);
        var radarDayData = [distance, averageSpeed, maxSpeed, hour];
        radarData.unshift(radarDayData);


        var distanceAvg = Math.round(Math.random() * 8000 + 4000);
        var averageSpeedAvg = +(Math.random() * 4 + 4).toFixed(3);
        var maxSpeedAvg = averageSpeedAvg + +(Math.random() * 2).toFixed(2);
        var hourAvg = +(distance / 1000 / averageSpeed).toFixed(1);
        var radarDayDataAvg = [
          distanceAvg,
          averageSpeedAvg,
          maxSpeedAvg,
          hourAvg
        ];
        radarDataAvg.unshift(radarDayDataAvg);*/
      }

      // 颜色设置
      let color = {
        linearYtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#f5b44d"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearGtoB: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#43dfa2"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        linearBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#1c98e8"
            },
            {
              offset: 1,
              color: "#28f8de"
            }
          ]
        },
        areaBtoG: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "rgba(35,184,210,.2)"
            },
            {
              offset: 1,
              color: "rgba(35,184,210,0)"
            }
          ]
        }
      };
      let option = {
       /* title: {
          text: "",
          textStyle: {
            color: "#D3D6DD",
            fontSize: 24,
            fontWeight: "normal"
          },
          subtext: year + "/" + weekCategory[6],
          subtextStyle: {
            color: "#fff",
            fontSize: 16
          },
          top: 20,
          left: 20
        },*/
      /*  legend: {
          top: 60,
          left: 20,
          orient: "vertical",
          itemGap: 15,
          itemWidth: 12,
          itemHeight: 12,
          data: ["设备总数", "活跃设备数"],
          textStyle: {
            color: "#fff",
            fontSize: 14
          }
        },*/
        tooltip: {
          trigger: "item"
        },
       /* radar: {
          center: ["65%", "25%"],
          radius: "30%",
          name: {
            color: "#fff"
          },
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              color: color.linearYtoG,
              opacity: 0.6
            }
          },
          splitLine: {
            lineStyle: {
              color: color.linearYtoG,
              opacity: 0.6
            }
          },
          splitArea: {
            areaStyle: {
              color: "#fff",
              opacity: 0.1,
              shadowBlur: 25,
              shadowColor: "#000",
              shadowOffsetX: 0,
              shadowOffsetY: 5
            }
          },
          indicator: [
            {
              name: "服务态度",
              max: maxData
            },
            {
              name: "产品质量",
              max: 10
            },
            {
              name: "任务效率",
              max: 12
            },
            {
              name: "售后保障",
              max: 3.5
            }
          ]
        },*/
        grid: {
          x: "8%",
          width: "88%",
          y: "10%"
        },
        xAxis: {
          type: "category",
          position: "bottom",
          axisLine: true,
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12
          },
          data: weekCategory
        },
        // 下方Y轴
        yAxis: {
          name: "",
          nameLocation: "end",
          nameGap: 24,
          nameTextStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize: 14
          },
          max: maxData,
          splitNumber: 4,

          axisLine: {
            lineStyle: {
              opacity: 0
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              opacity: 0.1
            }
          },
          axisLabel: {
            color: "rgba(255,255,255,.8)",
            fontSize: 12
          }
        },
        series: [
          /*{
            name: "",
            type: "radar",
            symbolSize: 0,
            data: [
              {
                value: radarDataAvg[6],
                name: "设备总数",
                itemStyle: {
                  normal: {
                    color: "#f8d351"
                  }
                },
                lineStyle: {
                  normal: {
                    opacity: 0
                  }
                },
                areaStyle: {
                  normal: {
                    color: "#f8d351",
                    shadowBlur: 25,
                    shadowColor: "rgba(248,211,81,.3)",
                    shadowOffsetX: 0,
                    shadowOffsetY: -10,
                    opacity: 1
                  }
                }
              },
              {
                value: radarData[6],
                name: "活跃设备数",
                itemStyle: {
                  normal: {
                    color: "#43dfa2"
                  }
                },
                lineStyle: {
                  normal: {
                    opacity: 0
                  }
                },
                areaStyle: {
                  normal: {
                    color: color.linearGtoB,
                    shadowBlur: 15,
                    shadowColor: "rgba(0,0,0,.2)",
                    shadowOffsetX: 0,
                    shadowOffsetY: 5,
                    opacity: 0.8
                  }
                }
              }
            ]
          },*/
          {
            name: "",
            type: "line",
            smooth: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: "#fff"
              }
            },
            lineStyle: {
              normal: {
                color: color.linearBtoG,
                width: 3
              }
            },
            areaStyle: {
              normal: {
                color: color.areaBtoG
              }
            },
            data: weekLineData,
            lineSmooth: true,
            markLine: {
              silent: true,
              data: [
                {
                  type: "average",
                  name: "平均值"
                }
              ],
              precision: 0,
              label: {
                normal: {
                  formatter: "平均值: \n {c}"
                }
              },
              lineStyle: {
                normal: {
                  color: "rgba(248,211,81,.7)"
                }
              }
            },
            tooltip: {
              position: "top",
              formatter: "{c} 次",
              backgroundColor: "rgba(28,152,232,.2)",
              padding: 6
            }
          },
          {
            name: "占位背景",
            type: "bar",
            itemStyle: {
              normal: {
                show: true,
                color: "#000",
                opacity: 0
              }
            },
            silent: true,
            barWidth: "50%",
            data: weekMaxData,
            animation: false
          }
        ]
      };

      this.chart.setOption(option);
    }
  },
  destroyed() {
    window.onresize = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
