<template>
    <div id="centreLeft1">
        <div class="bg-color-black">
            <div class="d-flex pt-2 pl-2">
                <span style="color:#5cd9e8">
                   <icon name="chart-bar"></icon>
                </span>
                <span class="fs-xl text mx-2">设备统计</span>
            </div>
            <div class="d-flex jc-center">
                <centreLeft1Chart/>
            </div>
            <div class="bottom-data">
                <div class="item-box" v-for="(item,index) in numberData" :key="index">
                    <div class="d-flex">
                        <dv-digital-flop :config="item.number" style="width:2.5rem;height:.625rem;"/>
                    </div>
                    <p class="text" style="text-align: center;">
                        {{item.text}}
                        <span class="colorYellow"></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import centreLeft1Chart from "@/components/echart/centerLeft/centreLeftChart";

    export default {
        data() {
            return {
                numberData: [
                    {
                        number: {
                            number: [0],
                            content: "{nt}"
                        },
                        text: "离线设备(台)"
                    },
                    {
                        number: {
                            number: [41],
                            content: "{nt}"
                        },
                        text: "全部设备(台)"
                    },
                   /* {
                        number: {
                            number: [10],
                            content: "{nt}"
                        },
                        text: "今日报警已处理(次)"
                    },
                    {
                        number: {
                            number: [53],
                            content: "{nt}"
                        },
                        text: "今日报警未处理(次)"
                    }*/
                ]
            };
        },
        components: {
            centreLeft1Chart
        },
        mounted() {
            this.changeTiming();
        },
        methods: {
            changeTiming() {
                setInterval(() => {
                    this.changeNumber();
                }, 3000);
            },
            changeNumber() {
                //this.numberData.forEach((item, index) => {
                   // item.number.number[0] += ++index;
                   // item.number = {...item.number};
               // });
            }
        }
    };
</script>

<style lang="scss">
    #centreLeft1 {
        padding: 0.2rem;
        height: 100%;
        /*min-width: 3.75rem;*/
        border-radius: 0.0625rem;

        .bg-color-black {
            height: 100%;
            border-radius: 0.125rem;
        }

        .text {
            color: #c3cbde;
        }

        .chart-box {
            margin-top: 0.2rem;
            width: 2.125rem;
            height: 2.125rem;

            .active-ring-name {
                padding-top: 0.125rem;
            }
        }

        .bottom-data {
            .item-box {
                margin-top: 15px;
                float: right;
                position: relative;
                width: 50%;
                color: #d3d6dd;
                .coin {
                    position: absolute;
                    left: 0.1rem;
                    top: 0.2125rem;
                    font-size: 0.25rem;
                    color: #ffc107;
                }

                .colorYellow {
                    color: yellowgreen;
                }
            }
        }
    }
</style>
