<template>
    <div id="centreRight2">
        <div class="bg-color-black">
            <div class="d-flex pt-2 pl-2">
              <span style="color:#5cd9e8">
               <icon name="align-left"></icon>
             </span>
                <span class="fs-xl text mx-2">报警管理</span>
            </div>
            <div class="d-flex jc-center body-box" style=" margin-top: 0;">
                <div class="item-box" v-for="(item,index) in numberData" :key="index">
                    <div class="d-flex">
                        <dv-digital-flop :config="item.number" style="width:2.5rem;height:.625rem;"/>
                    </div>
                    <p class="text" style="text-align: center;">
                        {{item.text}}
                        <span class="colorYellow"></span>
                    </p>
                </div>


              <!--  <dv-capsule-chart :config="config" style="width: 100%;height:2rem"/>
                <centreRight2Chart></centreRight2Chart>-->
            </div>
        </div>
    </div>
</template>

<script>
 /*   import centreRight2Chart from "@/components/echart/centreRight/centreRightChart";*/

    export default {
        data() {
            return {
                numberData: [
                    {
                        number: {
                            number: [2.75],
                            content: "{nt}"
                        },
                        text: "挠度黄色报警阈值"
                    },
                    {
                        number: {
                            number: [3],
                            content: "{nt}"
                        },
                        text: "挠度红色报警阈值"
                    },
                    /* {
                         number: {
                             number: [10],
                             content: "{nt}"
                         },
                         text: "今日报警已处理(次)"
                     },
                     {
                         number: {
                             number: [53],
                             content: "{nt}"
                         },
                         text: "今日报警未处理(次)"
                     }*/
                ],
                config: {
                    data: [
                        {
                            name: "京韵高架",
                            value: 100
                        },
                        {
                            name: "欣兰高架",
                            value: 98
                        },
                        {
                            name: "梅村高架",
                            value: 95
                        },
                        {
                            name: "五胡高架",
                            value: 95
                        },
                        {
                            name: "西峡高架",
                            value: 98
                        }
                    ]
                }
            };
        },
      /*  components: {centreRight2Chart},*/
        mounted() {
        },
        methods: {}
    };
</script>

<style lang="scss">
    #centreRight2 {
        padding: 0.0625rem;
        height: 100%;
        border-radius: 0.0625rem;

        .bg-color-black {
            padding: 0.0625rem;
            height: 100%;
            border-radius: 0.125rem;
        }

        .text {
            color: #c3cbde;
        }

        .body-box {
            border-radius: 0.125rem;
            overflow: hidden;
        }
    }
</style>
