<template>
    <div id="center" style="padding: 10px">
        <div id="container" style="width:100%; height: 45vh"></div>
    </div>
</template>

<script>
    import AMap from 'AMap'

    export default {
        data() {
            return {
                img:require('@/assets/demo.png'),
                markIcon:require('@/assets/mark.png')
            }
        },
        components: {},
        mounted() {
            this.init()
        },
        methods: {
            init() {
                let map = new AMap.Map('container', {
                    resizeEnable: true,
                    rotateEnable: true,
                    pitchEnable: true,
                    zoom: 17,
                    pitch: 70,
                    rotation: 0,
                    viewMode: '3D',
                    buildingAnimation: true,
                    expandZoomRange: true,
                    zooms: [3, 20],
                    center: [116.846279, 36.508126]
                })
                const icon = this.markIcon
                addMarker(icon);
                function addMarker(icon) {
                    map.clearMap();
                    var marker = new AMap.Marker({
                        icon: icon,
                        map: map,
                        position: [116.846279, 36.508126]
                    });
                    AMap.event.addListener(marker, 'click', function () {
                        infoWindow.open(map, marker.getPosition());
                    });
                    //marker.setAnimation('AMAP_ANIMATION_BOUNCE');
                }

                var title = '京台高速（G3）济南长清短崮山高架桥',
                    content = [];
                    content.push(`K424+440 崮山高架桥是 G3 京台高速的一座跨路特大桥，桥梁中心桩号 K424+440。桥梁全长 1055.16m，桥面总宽为35.50m，左右幅桥净宽均为 15.75m。`);
                    content.push(`<img src="${this.img}" style="margin: 0;float: none;width: 100%;">`);
                var infoWindow = new AMap.InfoWindow({
                    isCustom: true,
                    content: createInfoWindow(title, content.join("")),
                    offset: new AMap.Pixel(16, -45)
                });

                function createInfoWindow(title, content) {
                    var info = document.createElement("div");
                    info.className = "custom-info input-card content-window-card";

                    var top = document.createElement("div");
                    var titleD = document.createElement("div");
                    var closeX = document.createElement("img");
                    top.className = "info-top";
                    titleD.innerHTML = title;
                    closeX.src = "//webapi.amap.com/images/close2.gif";
                    closeX.onclick = closeInfoWindow;

                    top.appendChild(titleD);
                    top.appendChild(closeX);
                    info.appendChild(top);

                    // 定义中部内容
                    var middle = document.createElement("div");
                    middle.className = "info-middle";
                    middle.style.backgroundColor = 'white';
                    middle.innerHTML = content;
                    info.appendChild(middle);

                    // 定义底部内容
                    var bottom = document.createElement("div");
                    bottom.className = "info-bottom";
                    bottom.style.position = 'relative';
                    bottom.style.top = '0px';
                    bottom.style.margin = '0 auto';
                    var sharp = document.createElement("img");
                    sharp.src = "//webapi.amap.com/images/sharp.png";
                    bottom.appendChild(sharp);
                    info.appendChild(bottom);
                    return info;
                }

                function closeInfoWindow() {
                    map.clearInfoWindow();
                }

                /* let path = [
                     [119.987163,31.797602],
                     [119.991786,31.794004]
                 ];

                 let polyline = new AMap.Polyline({
                     path: path,
                     isOutline: true,
                     outlineColor: '#ffeeff',
                     borderWeight: 1,
                     strokeColor: "red",
                     strokeOpacity: 1,
                     strokeWeight: 10,
                     strokeStyle: "solid",
                     strokeDasharray: [10, 5],
                     lineJoin: 'round',
                     lineCap: 'round',
                     zIndex: 50,
                 })
                 polyline.setMap(map)
                 map.setFitView([ polyline ])*/
                AMap.plugin(['AMap.ToolBar', 'AMap.ControlBar', 'AMap.MapType'], function () {
                    const toolbar = new AMap.ToolBar();
                    map.addControl(toolbar);
                    const controlbar = new AMap.ControlBar({
                        position: {
                            right: '0',
                            top: '120px'
                        }
                    });
                    map.addControl(controlbar);
                    const maptype = new AMap.MapType({
                        defaultType: 1
                    });
                    map.addControl(maptype)
                });
            }
        }
    };
</script>

<style lang="scss" scoped>

    #center {
        display: flex;
        flex-direction: column;

       /* .up {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .item {
                border-radius: 0.0625rem;
                padding-top: 0.2rem;
                margin-top: 0.1rem;
                width: 32%;
                height: 0.875rem;
            }
        }

        .down {
            padding: 0.07rem 0.05rem;
            padding-bottom: 0;
            width: 100%;
            display: flex;
            height: 3.1875rem;
            justify-content: space-between;

            .bg-color-black {
                border-radius: 0.0625rem;
            }

            .ranking {
                padding: 0.125rem;
                width: 59%;
            }

            .percent {
                width: 40%;
                display: flex;
                flex-wrap: wrap;

                .item {
                    width: 50%;
                    height: 1.5rem;

                    span {
                        margin-top: 0.0875rem;
                        display: flex;
                        justify-content: center;
                    }
                }

                .water {
                    width: 100%;
                }
            }
        }*/


    }
</style>
